import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import Header from './Header';
import axios from 'axios';
import './TokenUpdate.css';
import { PublicKey } from '@solana/web3.js';

const apiUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL_PROD;

const TokenUpdateForm = () => {
  const { publicKey, signMessage } = useWallet();
  const [formData, setFormData] = useState({
    tokenMintAddress: '',
    image: '',
    description: '',
    links: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trendingData, setTrendingData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!publicKey) {
      alert('Please connect your wallet.');
      return;
    }

        try {
          new PublicKey(formData.tokenMintAddress);
        } catch (error) {
          alert('Invalid token mint address.');
          return;
        }

    setIsSubmitting(true);

    const response = await fetch(`https://${apiUrl}/api/request-signature`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        publicKey: publicKey.toString(),
        formData,
      }),
    });

    const { message } = await response.json();

    const encodedMessage = new TextEncoder().encode(message);
    const signature = await signMessage(encodedMessage);

    const verifyResponse = await fetch(`https://${apiUrl}/api/verify-and-update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        publicKey: publicKey.toString(),
        signature: Array.from(signature),
        formData,
      }),
    });

    const result = await verifyResponse.json();

    setIsSubmitting(false);

    if (result.success) {
      alert('Token details updated successfully.');
    } else {
      alert(`Failed to update token details: ${result.error}`);
    }
  };

          useEffect(() => {
            const fetchTrendingData = async () => {
                try {
                    const response = await axios.get(`https://${apiUrl}/api/trending`);
                    setTrendingData(response.data);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            };
            fetchTrendingData();
            const interval = setInterval(() => {
                fetchTrendingData();
            }, 60000);
            return () => clearInterval(interval);
        }, []);

  return (
    <div className="page">
        <Header search={false} tokens={trendingData.slice(0,10)} />
        <div className="content2">
            <form onSubmit={handleSubmit}>
                <label htmlFor="tokenMintAddress">Token Mint Address <span style={{opacity: "0.5", fontSize: "11px", fontWeight: "200"}}>(required)</span></label>
                <input type="text" name="tokenMintAddress" value={formData.tokenMintAddress} onChange={handleChange} placeholder="Token Mint Address" required />
                <div className="spacer"></div>
                <div className="spacer"></div>
                <label htmlFor="image">Token Logo <span style={{opacity: "0.5", fontSize: "11px", fontWeight: "200"}}>(min 125x125 px)</span></label>
                <input type="text" name="image" value={formData.image} onChange={handleChange} placeholder="Image URL"   />
                <div className="spacer"></div>
                <div className="spacer"></div>
                <label htmlFor="description">Description <span style={{opacity: "0.5", fontSize: "11px", fontWeight: "200"}}>(max 300 words)</span></label>
                <textarea name="description" value={formData.description} onChange={handleChange} placeholder="Description"  />
                <div className="spacer"></div>
                <div className="spacer"></div>
                <label htmlFor="links">Links</label>
                <input type="text" name="website" value={formData.links.website} onChange={handleChange} placeholder="Website link"  />
                <div className="spacer"></div>
                <input type="text" name="twitter" value={formData.links.twitter} onChange={handleChange} placeholder="Twitter link"  />
                <div className="spacer"></div>
                <input type="text" name="telegram" value={formData.links.telegram} onChange={handleChange} placeholder="Telegram link"  />
                <div className="spacer"></div>
                <input type="text" name="discord" value={formData.links.discord} onChange={handleChange} placeholder="Discord link"  />
                <div className="spacer"></div>
                <div className="spacer"></div>
                <div className="spacer"></div>
                <div className="spacer"></div>
                <button type="submit" disabled={isSubmitting}>Update Token</button>

            </form>
        </div>
    </div>
  );
};

export default TokenUpdateForm;