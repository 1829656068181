import React, { useState, useEffect, useCallback } from 'react';
import './Main.css'
import * as FaIcon from "react-icons/fa";
import Tabs from './Tabs'
import Ads from './Ads'
import TokenTable from './TokenTable'
import Header from './Header.js'
import Trending from './Trending'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import SearchModal from './SearchModal';

const apiUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL_PROD;

let running = false;

const Main = (_theme) => {
    const [originalTableData, setOriginalTableData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [trendPeriod, settrendPeriod] = useState('1H');
    const [currentPeriod, setCurrentPeriod] = useState('24h');
    const [activeFilter, setActiveFilter] = useState('trending');
    const [gainersPeriod, setgainersPeriod] = useState('h1');
    const [pairPeriod, setPairPeriod] = useState('newest');
    const [voltxn, setVoltxn] = useState('vol');
    const [showPeriods, setShowPeriods] = useState(false);
    const [currentlySortedBy, setCurrentlySortedBy] = useState({ type: 'volume_usd', period: 'h24' });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [trendingData, setTrendingData] = useState([]);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);

    const handlePeriodClick = () => {
        setShowPeriods(prev => !prev);
    };
    const handleTrendPeriodClick = (period) => {
        settrendPeriod(period);
    }
    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
    }   
    const handleVoltxnClick = (voltxn) => {
        setVoltxn(voltxn);
        let sortCriteria = {};
        if (voltxn === 'vol') {
            sortCriteria.type = 'volume_usd';
            switch (currentPeriod) {
                case '5m':
                    sortCriteria.period = 'm5';
                    setTableData([...originalTableData].sort((a, b) => b.volume_usd.m5 - a.volume_usd.m5));
                    break;
                case '1h':
                    sortCriteria.period = 'h1';
                    setTableData([...originalTableData].sort((a, b) => b.volume_usd.h1 - a.volume_usd.h1));
                    break;
                case '6h':
                    sortCriteria.period = 'h6';
                    setTableData([...originalTableData].sort((a, b) => b.volume_usd.h6 - a.volume_usd.h6));
                    break;
                case '24h':
                    sortCriteria.period = 'h24';
                    setTableData([...originalTableData].sort((a, b) => b.volume_usd.h24 - a.volume_usd.h24));
                    break;
                default:
                    break;
            }
        } else if (voltxn === 'txn') {
            sortCriteria.type = 'transactions';
            switch (currentPeriod) {
                case '5m':
                    sortCriteria.period = 'm5';
                    setTableData([...originalTableData].sort((a, b) => (b.transactions.m5.buys + b.transactions.m5.sells) - (a.transactions.m5.buys + a.transactions.m5.sells)));
                    break;
                case '1h':
                    sortCriteria.period = 'h1';
                    setTableData([...originalTableData].sort((a, b) => (b.transactions.h1.buys + b.transactions.h1.sells) - (a.transactions.h1.buys + a.transactions.h1.sells)));
                    break;
                case '6h':
                    sortCriteria.period = 'h6';
                    setTableData([...originalTableData].sort((a, b) => (b.transactions.h1.buys + b.transactions.h1.sells) * 6 - (a.transactions.h1.buys + a.transactions.h1.sells) * 6));
                    break;
                case '24h':
                    sortCriteria.period = 'h24';
                    setTableData([...originalTableData].sort((a, b) => (b.transactions.h24.buys + b.transactions.h24.sells) - (a.transactions.h24.buys + a.transactions.h24.sells)));
                    break;
                default:
                    break;
            }
        }
        setCurrentlySortedBy(sortCriteria);
    };
    const handleGainersPeriodClick = (period) => {
        setgainersPeriod(period);
        setCurrentlySortedBy({ type: 'price_change_percentage', period });
        switch (period) {
            case 'm5':
                setTableData([...originalTableData].sort((a, b) => b.price_change_percentage.m5 - a.price_change_percentage.m5));
                break;
            case 'h1':
                setTableData([...originalTableData].sort((a, b) => b.price_change_percentage.h1 - a.price_change_percentage.h1));
                break;
            case 'h6':
                setTableData([...originalTableData].sort((a, b) => b.price_change_percentage.h6 - a.price_change_percentage.h6));
                break;
            case 'h24':
                setTableData([...originalTableData].sort((a, b) => b.price_change_percentage.h24 - a.price_change_percentage.h24));
                break;
            default:
                break;
        }
    };
    const handlePairPeriodClick = (period) => {
        setPairPeriod(period);
        const now = new Date();
        let filteredData = [];
        let sortedData = [];
        switch (period) {
            case 'newest':
                filteredData = originalTableData.sort((a, b) => new Date(b.pool_created_at) - new Date(a.pool_created_at));
                break;
            case '1H':
                filteredData = originalTableData.filter(item => new Date(item.pool_created_at) >= new Date(now - 1 * 60 * 60 * 1000));
                break;
            case '6H':
                filteredData = originalTableData.filter(item => new Date(item.pool_created_at) >= new Date(now - 6 * 60 * 60 * 1000));
                break;
            case '24H':
                filteredData = originalTableData.filter(item => new Date(item.pool_created_at) >= new Date(now - 24 * 60 * 60 * 1000));
                break;
            case '3D':
                filteredData = originalTableData.filter(item => new Date(item.pool_created_at) >= new Date(now - 3 * 24 * 60 * 60 * 1000));
                break;
            case '7D':
                filteredData = originalTableData.filter(item => new Date(item.pool_created_at) >= new Date(now - 7 * 24 * 60 * 60 * 1000));
                break;
            default:
                filteredData = [...originalTableData];
                break;
        }
        sortedData = filteredData.sort((a, b) => new Date(b.pool_created_at) - new Date(a.pool_created_at));
        setTableData(sortedData);
        setCurrentlySortedBy({ type: 'pair_created_at', period });
    }

    const sortTableData = (data, criteria) => {
        const { type, period } = criteria;
        if (type === 'volume_usd') {
            return data.sort((a, b) => b.volume_usd[period] - a.volume_usd[period]);
        } else if (type === 'price_change_percentage') {
            return data.sort((a, b) => (b.price_change_percentage[period] || 0) - (a.price_change_percentage[period] || 0));
        }
        return data;
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            func(...args);
          }, delay);
        };
    };

    const handleSearch = useCallback(
        debounce(async (query) => {
          setSearchQuery(query);
          const parts = query.toLowerCase().split('/').map(part => part.trim());
          
          const filteredData = originalTableData.filter(token => {
            return parts.every(part =>
              token.name.toLowerCase().includes(part) ||
              token.address.toLowerCase().includes(part) ||
              token.tokenA.toLowerCase().includes(part) ||
              token.tokenAName.toLowerCase().includes(part) ||
              token.tokenAAddress.toLowerCase().includes(part)
            );
          });
      
          const sortedData = filteredData.sort((a, b) => {
            const scoreA = calculateMatchScore(a, parts);
            const scoreB = calculateMatchScore(b, parts);
            return scoreB - scoreA; 
          });
          setSearchData(sortedData);
        }, 300), 
        [ originalTableData ]
    );
      
      const calculateMatchScore = (token, queryParts) => {
        let score = 0;
        const properties = ['name', 'address', 'tokenA', 'tokenAName', 'tokenAAddress'];
        
        properties.forEach(prop => {
          queryParts.forEach(part => {
            if (token[prop].toLowerCase().startsWith(part)) {
              score += part.length; 
            } else if (token[prop].toLowerCase().includes(part)) {
              score += 0.5; 
            }
          });
        });
      
        return score;
      };
      
    setTimeout(() => {
        const filterContainer = document.querySelector('.filter-container');
        let isDown = false;
        let startX;
        let scrollLeft;
        
        filterContainer.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - filterContainer.offsetLeft;
            scrollLeft = filterContainer.scrollLeft;
        });
        
        filterContainer.addEventListener('mouseleave', () => {
            isDown = false;
        });
        
        filterContainer.addEventListener('mouseup', () => {
            isDown = false;
        });
        
        filterContainer.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - filterContainer.offsetLeft;
            const walk = (x - startX) * 2; 
            filterContainer.scrollLeft = scrollLeft - walk;
        });
    } , 1000);

    useEffect(() => {
        const fetchData = async () => {
            if (running) return;
            running = true;
            let tokenData = [];
            let currentPage = 1;
            let moreDataAvailable = true;

            while (moreDataAvailable) {
                try {
                    const response = await axios.get(`https://${apiUrl}/api/pairsData`, {
                        params: {
                            page: currentPage,
                            pageSize: 100
                        }
                    });

                    const data = response.data;
                    console.log('Data fetched:', data.length);

                    if (data.length < 100) {
                        moreDataAvailable = false;
                        tokenData = [...tokenData, ...data];
                        setTableData(tokenData);
                        setOriginalTableData(tokenData);
                        setSearchData(tokenData.slice(0, 10));
                        console.log('All data fetched:', tokenData.length);
                        currentPage++;
                    } else {
                        tokenData = [...tokenData, ...data];
                        setTableData(tokenData);
                        setOriginalTableData(tokenData);
                        setSearchData(tokenData.slice(0, 10));
                        currentPage++;
                    }
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                    moreDataAvailable = false;
                }
            }
            setTableData(tokenData);
            running = false;
        };

        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (searchQuery === '') {
          setTableData(originalTableData);
        }
      }, [searchQuery]);

      useEffect(() => {
        const fetchTrendingData = async () => {
            try {
                const response = await axios.get(`https://${apiUrl}/api/trending`);
                setTrendingData(response.data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };
        fetchTrendingData();
        const interval = setInterval(() => {
            fetchTrendingData();
        }, 60000);
        return () => clearInterval(interval);
    }, []);


    
    return (
    <div className='page'>
        {showSearchModal && <SearchModal setShowSearchModal={setShowSearchModal} onSearch={handleSearch} searchData={searchData}/>}
        <ToastContainer />
        <Header search={true} onSearch={handleSearch} tokens={trendingData.slice(0, 10)} setShowSearchModal={() => setShowSearchModal(prev => !prev)} />
        <div className="content">
            <div className="top">
                <Trending tokens={trendingData.slice(0,10)} />
                <Tabs />
                <Ads />
                <div className="filter-container">
                <div className="time-filter" onClick={() => handlePeriodClick()}>
                    <FaIcon.FaClock className='reacticon' size={14}/>
                    <p>{currentPeriod}</p>
                    <FaIcon.FaAngleDown className='reacticon' size={16}/>
                </div>
                    {showPeriods ?
                    <div className="time-periods">
                        <div className="time-period" onClick={() =>  {
                            setCurrentPeriod('5m')
                            setShowPeriods(prev => !prev);
                            tableData.sort((a, b) => b.volume_usd.m5 - a.volume_usd.m5);
                        }}>5 min</div>
                        <div className="time-period" onClick={() => {
                            setCurrentPeriod('1h')
                            setShowPeriods(prev => !prev);
                            tableData.sort((a, b) => b.volume_usd.h1 - a.volume_usd.h1);
                        }}>1 hour</div>
                        <div className="time-period" onClick={() => {
                            setCurrentPeriod('6h')
                            setShowPeriods(prev => !prev);
                            tableData.sort((a, b) => b.volume_usd.h6 - a.volume_usd.h6);
                        }}>6 hour</div>
                        <div className="time-period" onClick={() => {
                            setCurrentPeriod('24h')
                            setShowPeriods(prev => !prev);
                            tableData.sort((a, b) => b.volume_usd.h24 - a.volume_usd.h24);
                        }}>24 hour</div>
                    </div> : ''}
                <div className={`buttons ${activeFilter === 'top' ? 'active' : ''}`} onClick={() => {
                    if (currentlySortedBy.type !== 'volume_usd') {
                        handleVoltxnClick('vol')
                    }
                    handleFilterClick('top')}}>
                <FaIcon.FaChartBar className='reacticon' size={16}/>
                    <p>Top</p>
                    {activeFilter === 'top' ?
                    <>
                        <div className={`timeframebtn ${voltxn === 'vol' ? 'active' : ''}`} onClick={() => handleVoltxnClick('vol')}>Volume</div>
                        <div className={`timeframebtn ${voltxn === 'txn' ? 'active' : ''}`} onClick={() => handleVoltxnClick('txn')}>Txs</div>
                    </>
                    : ''}
                </div>
                <div className={`buttons ${activeFilter === 'gainers' ? 'active' : ''}`} onClick={() => {
                    if (currentlySortedBy.type !== 'price_change_percentage') {
                        handleGainersPeriodClick('m5')
                    }
                    handleFilterClick('gainers')}}>
                    <FaIcon.FaArrowUp className='reacticon' size={16}/>
                    <p>Gainers</p>
                    {activeFilter === 'gainers' ?
                    <>
                        <div className={`timeframe ${gainersPeriod === 'm5' ? 'active' : ''}`} onClick={() => handleGainersPeriodClick('m5')}>5M</div>
                        <div className={`timeframe ${gainersPeriod === 'h1' ? 'active' : ''}`} onClick={() => handleGainersPeriodClick('h1')}>1H</div>
                        <div className={`timeframe ${gainersPeriod === 'h6' ? 'active' : ''}`} onClick={() => handleGainersPeriodClick('h6')}>6H</div>
                        <div className={`timeframe ${gainersPeriod === 'h24' ? 'active' : ''}`} onClick={() => handleGainersPeriodClick('h24')}>24H</div>
                    </>
                    : ''}
                </div>
                <div className={`buttons ${activeFilter === 'newpairs' ? 'active' : ''}`} onClick={() => {
                    if (currentlySortedBy.type !== 'pair_created_at') {
                        handlePairPeriodClick('newest')
                    }
                    handleFilterClick('newpairs')
                    }}>
                    <FaIcon.FaLeaf className='reacticon' size={16}/>
                    <p>New Pairs</p>
                    {activeFilter === 'newpairs' ? 
                    <>
                        <div className={`timeframe ${pairPeriod === 'newest' ? 'active' : ''}`} onClick={() => handlePairPeriodClick('newest')}>Newest</div>
                        <div className={`timeframe ${pairPeriod === '1H' ? 'active' : ''}`} onClick={() => handlePairPeriodClick('1H')}>1H</div>
                        <div className={`timeframe ${pairPeriod === '6H' ? 'active' : ''}`} onClick={() => handlePairPeriodClick('6H')}>6H</div>
                        <div className={`timeframe ${pairPeriod === '24H' ? 'active' : ''}`} onClick={() => handlePairPeriodClick('24H')}>24H</div>
                        <div className={`timeframe ${pairPeriod === '3D' ? 'active' : ''}`} onClick={() => handlePairPeriodClick('3D')}>3D</div>
                        <div className={`timeframe ${pairPeriod === '7D' ? 'active' : ''}`} onClick={() => handlePairPeriodClick('7D')}>7D</div>
                    </> : ''
                    }
                </div>
            </div>
            </div>
            <div className="bottom">
                <TokenTable data={tableData} timeframe={currentPeriod} />
            </div>
        </div>
    </div>
  )
}

export default Main