import React from 'react'
import './SearchModal.css'
import * as FaIcons from 'react-icons/fa';

const SearchModal = ({ setShowSearchModal, onSearch, searchData }) => {

    const handleSearch = (e) => {
        onSearch(e.target.value);
    }
    const handleBackgroundClick = (e) => {
        setShowSearchModal(false);
    }
    const handleContentClick = (e) => {
        e.stopPropagation();
    }

    function formatNumber(number, symbol) {
        let numStr = number.toString();
    
        if (numStr.includes('e')) {
            numStr = number.toFixed(20).replace(/0+$/, ''); 
        }
    
        const decimalIndex = numStr.indexOf('.');
        const integerPart = decimalIndex === -1 ? numStr : numStr.slice(0, decimalIndex);
        const decimalPart = decimalIndex === -1 ? '' : numStr.slice(decimalIndex + 1);
    
        const formattedIntegerPart = Number(integerPart).toLocaleString();
    
        if (integerPart === '0') {
            const leadingZeros = decimalPart.match(/^0+/);
            const zeroCount = leadingZeros ? leadingZeros[0].length : 0;
            const significantDigits = decimalPart.slice(zeroCount, zeroCount + 4);
    
            if (zeroCount > 1) {
                if (!symbol) return `0.0<sub>${zeroCount}</sub>${significantDigits}`;
                return `0.0<sub>${zeroCount}</sub>${significantDigits} ${symbol}`;
            }
    
            if (significantDigits === '') {
                if (!symbol) return `0.0`;
                return `0 ${symbol}`;
            }
    
            if (!symbol) return `0.${significantDigits}`;
            return `0.${significantDigits} ${symbol}`;
        }
    
        const trimmedDecimalPart = decimalPart.slice(0, 2);
    
        const match = trimmedDecimalPart.match(/0+$/);
        if (match) {
            const subIndex = match.index;
            const beforeSub = trimmedDecimalPart.slice(0, subIndex);
            const afterSub = match[0];
            if (!symbol) return `${formattedIntegerPart}.${beforeSub}<sub>${afterSub}</sub>`;
            return `${formattedIntegerPart}.${beforeSub}<sub>${afterSub}</sub> ${symbol}`;
        }
    
        if (!symbol) return `${formattedIntegerPart}.${trimmedDecimalPart}`;
        return `${formattedIntegerPart}.${trimmedDecimalPart} ${symbol}`;
    }

    function numberWithCommas(x) {
        if (isNaN(x)) return x;
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }

    const PercentageChange = ({ value }) => {
        const color = value >= 0 ? '#81c784' : '#e57373';
        return (
          <p style={{ textAlign: "right", color }}>
            {numberWithCommas(value)}%
          </p>
        );
      };

      const formatNumber2 = (num) => {
        num = Number(num);
        if (num === null || num === undefined) return 'N/A';
        if (num < 1000) return num.toFixed(2);
        const units = ['k', 'm', 'b', 't'];
        let unitIndex = -1;
        let scaledNum = num;
        while (scaledNum >= 1000 && unitIndex < units.length - 1) {
            scaledNum /= 1000;
            unitIndex++;
        }
        return scaledNum.toFixed(2) + units[unitIndex];
    };

    const timeSincePairCreated = (dateTime) => {
        const date = new Date(dateTime);
        const now = new Date();
        const diff = now - date;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4);
        const years = Math.floor(months / 12);
        if (years > 0) {
          return `${years}y`;
        }
        if (months > 0) {
          return `${months}mo`;
        }
        if (weeks > 0) {
          return `${weeks}w`;
        }
        if (days > 0) {
          return `${days}d`;
        }
        if (hours > 0) {
          return `${hours}h`;
        }
        if (minutes > 0) {
          return `${minutes}m`;
        }   
        return `${seconds}s`;
    };

  return (
    <div>
        <div className="search-modal" onClick={handleBackgroundClick}>
            <div className="search-modal-content" onClick={handleContentClick}>
            <div className="search-modal-header">
                <input className='modalinput' type="text" placeholder="Search..." onChange={handleSearch} autoFocus={true}/>
            </div>
            <div className="search-modal-body">
            {searchData.length > 0 && (
                  <div className='searchcontainer'>
                  <div className="search-results" style={{paddingLeft: "10px", paddingRight: "30px"}}>
                    {searchData.map((token, index) => {
                        console.log(token);
                        return (
                          <div key={index} className="search-result" onClick={() => window.location.href = `solana?market=${token.address}`}>
                            <div className="search-result-index" style={{marginRight: "20px"}}>#{index + 1}</div>
                            <div className="stack">
                              <div className="flex">
                                {token.img?.toString() === 'missing.png' ? <FaIcons.FaQuestionCircle size={24} style={{marginRight: "8px", opacity: "0.2"}} /> : <img style={{marginRight: "8px", borderRadius: "100vw", width: "24px", height: "24px"}} src={token.img} alt="" />}
                                <div className="search-result-name inline">
                                    <div className='borderbox2'>
                                    {token.name.split('/')[0]}
                                    <span className='faded' style={{marginLeft: "4px"}}>/{token.name.split('/')[1]}</span></div>
                                    <span style={{fontSize: "14px", fontWeight: "400", marginLeft: "8px"}}>{token.tokenAName}</span>
                                    <div className="pair-created" style={{display: "flex", alignItems: "center", justifyContent: "center", color: "grey", gap: "2px", marginLeft: "8px"}}>
                                        <FaIcons.FaSeedling size={12} />
                                        <div className="" style={{fontSize: "12px"}}>{timeSincePairCreated(token.pool_created_at)}</div>
                                    </div>
                                    </div>
                              </div>
                              <div className="flex" style={{marginTop: "6px", fontSize: "14px"}}>
                                <p dangerouslySetInnerHTML={{__html: "$"+formatNumber(token.base_token_price_usd)}} style={{marginRight: "8px", marginTop: "2px"}}></p>
                                <PercentageChange value={token.price_change_percentage.h24} />
                                <p style={{marginLeft: "20px"}}><span style={{fontWeight:"200", opacity: "0.6"}}>Liquidity:</span> {"$"+formatNumber2(token.reserve_in_usd)}</p>
                                <p style={{marginLeft: "20px"}}><span style={{fontWeight:"200", opacity: "0.6"}}>Vol 24h:</span> {"$"+formatNumber2(token.volume_usd.h24)}</p>
                                <p style={{marginLeft: "20px"}}><span style={{fontWeight:"200", opacity: "0.6"}}>Mcap:</span> {"$"+formatNumber2(token.market_cap_usd || token.fdv_usd)}</p>
                              </div>
                              <div className="flex">
                              <div className="search-result-token" style={{fontWeight: "200"}}
                              onClick={() => { 
                                navigator.clipboard.writeText(token.address);
                                alert("Copied to clipboard");
                              }}>Pair: {token.address.slice(0,5) + "..." + token.address.slice(-5)}</div>
                              <div className="search-result-token" style={{fontWeight: "200", marginLeft: "20px"}}
                                onClick={() => { 
                                    navigator.clipboard.writeText(token.tokenAAddress);
                                    alert("Copied to clipboard");
                                }}
                              >Token: {token.tokenAAddress.slice(0,5) + "..." + token.tokenAAddress.slice(-5)}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  </div>
                )}
            </div>
            </div>
        </div>
    </div>
  )
}

export default SearchModal