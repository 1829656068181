import React from 'react'
import './Ads.css'
import * as FaIcon from "react-icons/fa";

const Ads = () => {
    const advertImage = 'https://via.placeholder.com/728x90.png?text=Visit+WhoIsHostingThis.com+Buyers+Guide'
  return (
    <div className='advert-container'>
        <div className="ad">
            <div className="ad-text">
                <div className="admain">
                    <img style={{width: "24px", height: "24px", borderRadius: "100vw"}} src={advertImage} alt="" />
                    <p className='ad1'>Example Advert</p>
                </div>
                <p className='ad2'>This is a placeholder advert description for development.</p>
            </div>
            <a href="#">Link</a>
        </div>
        <div className="options">
            <FaIcon.FaInfoCircle className='' size={14}/>
            <p>Ad</p>
        </div>
    </div>
  )
}

export default Ads