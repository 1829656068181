import React, { useState, useEffect, useCallback, useRef } from 'react'
import './Main.css'
import { createChart } from 'lightweight-charts';
import './TokenPage.css'
import Details from './Details'
import DOMPurify from 'dompurify'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header.js'
import Trending from './Trending.js';
import axios from 'axios';
import SearchModal from './SearchModal.js';

let running
const apiUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL_PROD;
let initloaded = false;

const TokenPage = (_theme) => {  

    const MARKET_ADDRESS = new URLSearchParams(window.location.search).get('market');
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [tokenData, setTokenData] = useState({});
    const [poolData, setPoolData] = useState({});
    const [latestPrice, setLatestPrice] = useState(0);
    const [latestBasePrice, setLatestBasePrice] = useState(0);
    const [trendingTokens, setTrendingTokens] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [originalData, setOriginalData] = useState([]); 
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [trendingData, setTrendingData] = useState([]);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const observer = useRef();

    function formatNumber2(number, symbol) {
      let numStr = number.toString();
    
      if (Number.isInteger(number)) {
        return number.toLocaleString(); 
      }
    
      const decimalIndex = numStr.indexOf('.');
      const integerPart = decimalIndex === -1 ? numStr : numStr.slice(0, decimalIndex);
      const decimalPart = decimalIndex === -1 ? '' : numStr.slice(decimalIndex + 1);
      
      const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString();
    
      if (integerPart === '0') {
        const leadingZeros = decimalPart.match(/^0+/);
        const zeroCount = leadingZeros ? leadingZeros[0].length : 0;
        const significantDigits = decimalPart.slice(zeroCount, zeroCount + 4);
        const zerostring = '0'.repeat(zeroCount);
    
        if (zeroCount > 1) {
          if (!symbol) return `$0.${zerostring}${significantDigits}`;
          return `0.${zerostring}${significantDigits} ${symbol}`;
        }
        
        if (significantDigits === '') {
          if (!symbol) return `$0.0`;
          return `0 ${symbol}`;
        }
        
        if (!symbol) return `$0.${significantDigits}`;
        return `0.${significantDigits} ${symbol}`;
      }
    
      const trimmedDecimalPart = decimalPart.slice(0, 2);
    
      const match = trimmedDecimalPart.match(/0+$/);
      if (match) {
        const subIndex = match.index + match[0].length;
        const beforeSub = trimmedDecimalPart.slice(0, subIndex);
        const afterSub = trimmedDecimalPart.slice(subIndex);
        if (!symbol) return `${formattedIntegerPart}.${beforeSub}<sub>${afterSub}</sub>`;
        return `${formattedIntegerPart}.${beforeSub}<sub>${afterSub}</sub> ${symbol}`;
      }
    
      if (!symbol) return `${formattedIntegerPart}.${trimmedDecimalPart}`;
      return `${formattedIntegerPart}.${trimmedDecimalPart} ${symbol}`;
    }

    /* CHART */
    async function fetchInitialData() {
      try {
        const initialData = await fetch(`https://${apiUrl}/api/ohlcv/initial/${MARKET_ADDRESS}`);
        const initialDataJson = await initialData.json();
        let mappedData = initialDataJson.map((item) => {
          return {
            time: item[0],
            open: item[1],
            high: item[2],
            low: item[3],
            close: item[4],
            volume: item[5],
          };
        });
        mappedData.sort((a, b) => a.time - b.time);
        mappedData = mappedData.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.time === item.time
          ))
        );
        return mappedData;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
    async function fetchCompleteData() {
      let start = Date.now();
      try {
        const rawData = await fetch(`https://${apiUrl}/api/ohlcv/${MARKET_ADDRESS}`);
        const allData = await rawData.json();
        
        let mappedData = allData.map((item) => {
          return {
            time: item[0],
            open: item[1],
            high: item[2],
            low: item[3],
            close: item[4],
            volume: item[5],
          };
        });
        mappedData.sort((a, b) => a.time - b.time);
        mappedData = mappedData.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.time === item.time
          ))
        );
        return mappedData;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
    async function updateChart(data) {
      try {
        const chartContainer = document.getElementById('tradingview-chart-container');
        const chartOptions = {
          layout: {
            textColor: 'white',
            background: {
              color: '#44475050'
            },
          },
          grid: {
            vertLines: {
              color: '#444750',
            },
            horzLines: {
              color: '#444750',
            },
          },
          priceScale: {
            borderColor: '#485c7b',
          },
          timeScale: {
            borderColor: '#485c7b',
          },
          crosshair: {
            vertLine: {
              color: '#485c7b',
              width: 0.5,
              style: 0,
              visible: true,
              labelVisible: true,
            },
            horzLine: {
              color: '#485c7b',
              width: 0.5,
              style: 0,
              visible: true,
              labelVisible: true,
            },
          },
          localization: {
            priceFormatter: price => {
              return formatNumber2(price, '');
            }
          },
        };
        chartContainer.innerHTML = ''; 
        const chart = createChart(chartContainer, chartOptions);
        const candleChart = chart.addCandlestickSeries();
        candleChart.setData(data);
        chart.timeScale().fitContent();
        window.addEventListener("resize", () => {
          chart.resize(window.innerWidth, window.innerHeight);
          chart.timeScale().fitContent();
        });
      } catch (error) {
        console.error(error);
      }
    }
    useEffect(() => {
      const fetchDataAndUpdateChart = async () => {
        try {
          const initialData = await fetchInitialData();
          updateChart(initialData);
          const completeData = await fetchCompleteData();
          updateChart(completeData);
          //Set interval to fetch complete data and update chart every minute
          const intervalId = setInterval(async () => {
            const completeData = await fetchCompleteData();
            updateChart(completeData);
          }, 60000);

          return () => clearInterval(intervalId);
        } catch (err) {
          console.error(err);
        }
      };
      fetchDataAndUpdateChart();
    }, []);

    useEffect(() => {
      if (initloaded) return;
      if (!tokenData.tokenAAddress) return;
      initloaded = true;
      let tokenA = tokenData.tokenAAddress;
      let tokenB = tokenData.tokenBAddress;
      async function fetchRecentTransactions() {
          try {
              const serverResponse = await fetch(`https://${apiUrl}/api/trades/${tokenA}/${tokenB}/${pageLength}`);
              const serverResponseJson = await serverResponse.json();
              if (serverResponseJson.length === 0) return
              let sortedTransactions = serverResponseJson.sort((a, b) => b.trade.ui.date - a.trade.ui.date);
              sortedTransactions = sortedTransactions.filter((item, index, self) =>
                  index === self.findIndex((t) => (
                      t.trade.Transaction.Signature === item.trade.Transaction.Signature
                  ))
              );
              setRecentTransactions(sortedTransactions);
          } catch (error) {
              console.error('Failed to fetch recent transactions:', error);
          }
      }
      fetchRecentTransactions();
  }, [tokenData.tokenAAddress, tokenData.tokenBAddress]);

    /* RECENT TRANSACTIONS WEBSOCKET */
    useEffect(() => {
      if (!MARKET_ADDRESS) return;

      const ws = new WebSocket(`wss://${apiUrl}`);

      ws.onopen = () => {
          ws.send(JSON.stringify({ marketAddress: MARKET_ADDRESS }));
          setInterval(() => {
            ws.send(JSON.stringify({ message: "ping"}));
          }, 3000);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.message === "pong") return;

          let latestTokenPrice = data.trade.ui.price
          let latestBasePrice = data.trade.ui.nativeAmount / data.trade.ui.tokenAmount;
          setLatestPrice(latestTokenPrice);
          setLatestBasePrice(latestBasePrice);
 
          setRecentTransactions((prevData) => [data, ...prevData]);
          if (recentTransactions.length >= 1000) {
              setRecentTransactions((prevData) => prevData.slice(0, 999));
          }
      };

      ws.onclose = () => {
        console.log('Disconnected from server.');
        
      };

      ws.onerror = (error) => {
          console.error('WebSocket error:', error);
      };

      return () => {
          ws.close();
      };
    }, [MARKET_ADDRESS]);

    useEffect(() => {
      if (!tokenData.transactions) return;
      async function updateTokenData() {
        
        setTokenData((prevData) => {
          return {
            ...prevData,
            base_token_price_usd: latestPrice,
            base_token_price_quote_token: latestBasePrice,
            transactions: {
              ...prevData.transactions,
              h24: {
                ...prevData.transactions.h24,
                buys: prevData.transactions.h24.buys + 1, 
              },
            },
            };
      });
    }
    updateTokenData();
    }, [latestPrice, latestBasePrice]);
    
    /* TOKEN STATS */
    async function fetchTokenData() {
      let start = Date.now();
      try {
        const serverResponse = await fetch(`https://${apiUrl}/api/tokens/${MARKET_ADDRESS}`);
        const serverResponseJson = await serverResponse.json();
        setTokenData(serverResponseJson);
      } catch (error) {
        console.error('Failed to fetch token data:', error);
      }
    }
    useEffect(() => {
      fetchTokenData();
      setInterval(() => {
        fetchTokenData();
      }, 60000);
    }, []);

    // Get the trending tokens from the server
    useEffect(() => {
      async function fetchTrendingTokens() {
        try {
          const serverResponse = await fetch(`https://${apiUrl}/api/trending`);
          const serverResponseJson = await serverResponse.json();
          setTrendingTokens(serverResponseJson);
        } catch (error) {
          console.error('Failed to fetch trending tokens:', error);
        }
      }
      fetchTrendingTokens();
    }, []);

    /* UTILS */
    function formatTimeDifference(timestamp) {
          const currentTime = Date.now(); 
          const timeDiffInSeconds = Math.floor((currentTime - timestamp) / 1000); 
      
          if (timeDiffInSeconds < 60) {
            if (timeDiffInSeconds < 0) {
              return '0s';
            }
              return `${timeDiffInSeconds}s`;
          } else if (timeDiffInSeconds < 3600) {
              const minutes = Math.floor(timeDiffInSeconds / 60);
              return `${minutes}m`;
          } else if (timeDiffInSeconds < 86400) {
              const hours = Math.floor(timeDiffInSeconds / 3600);
              return `${hours}h`;
          } else {
              const days = Math.floor(timeDiffInSeconds / 86400);
              return `${days}d`;
          }
    }

    function formatNumber(number, bool) {

      if (number >= 1) {
        return number.toLocaleString();
      }

      let symbol = '';
      if (bool === true) {
        symbol = '$';
      }

        let numStr = number.toString();

        const [integerPart, decimalPart] = numStr.split('.');
        const formattedIntegerPart = parseInt(integerPart).toLocaleString();

        if (integerPart === '0') {
            const leadingZeros = decimalPart.match(/^0+/);
            const zeroCount = leadingZeros ? leadingZeros[0].length : 0;
            const significantDigits = decimalPart.slice(zeroCount, zeroCount + 4);

            if (zeroCount > 1) {
                return `${symbol}0.0<sub>${zeroCount}</sub>${significantDigits}`;
            } else if (zeroCount === 1) {
                return `${symbol}0.0${significantDigits}`;
            } else {
                return `${symbol}0.${significantDigits}`;
            }
        }

        return `${formattedIntegerPart}.${decimalPart}`;
    }

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeElapsed(prevTime => prevTime + 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);

    const NumberDisplay = ({ number, transaction }) => {
      const formattedNumber = formatNumber(number);
      const sanitizedNumber = DOMPurify.sanitize(formattedNumber); 

      return (
          <div style={{width: "15%", textAlign: "right", display: "flex", marginLeft: "auto", marginRight: "6px"}} dangerouslySetInnerHTML={{ __html: sanitizedNumber }} />
      );
    };

          const calculateMatchScore = (token, queryParts) => {
            let score = 0;
            const properties = ['name', 'address', 'tokenA', 'tokenAName', 'tokenAAddress'];
            
            properties.forEach(prop => {
              queryParts.forEach(part => {
                if (token[prop].toLowerCase().startsWith(part)) {
                  score += part.length; 
                } else if (token[prop].toLowerCase().includes(part)) {
                  score += 0.5; 
                }
              });
            });
          
            return score;
          };

  const shouldFlash = (date) => {
    return formatTimeDifference(date) === '0s';
  };

    const [pageNumber, setPageNumber] = useState(1);
    const [pageLength, setPageLength] = useState(20);
    const getSlicedData = (data) => {
      if (!data) return [];
              const startIndex = (pageNumber - 1) * pageLength;
              const endIndex = startIndex + pageLength;
              return data.slice(startIndex, endIndex);
    };

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
  };

      useEffect(() => {
        const fetchData = async () => {
            if (running) return;
            running = true;
            let tokenData = [];
            let currentPage = 1;
            let moreDataAvailable = true;

            while (moreDataAvailable) {
                try {
                    const response = await axios.get(`https://${apiUrl}/api/pairsData`, {
                        params: {
                            page: currentPage,
                            pageSize: 100
                        }
                    });

                    const data = response.data;

                    if (data.length < 100) {
                        moreDataAvailable = false;
                        tokenData = [...tokenData, ...data];
                        setOriginalData(tokenData);
                        setSearchData(tokenData.slice(0, 10));
                        console.log('All data fetched:', tokenData.length);
                        currentPage++;
                    } else {
                        tokenData = [...tokenData, ...data];
                        setOriginalData(tokenData);
                        setSearchData(tokenData.slice(0, 10));
                        currentPage++;
                    }
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                    moreDataAvailable = false;
                }
            }
            setOriginalData(tokenData);
            running = false;
        };

        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 300000); 

        return () => clearInterval(interval);
    }, []);

    const handleSearch = useCallback(
      debounce(async (query) => {
        setSearchQuery(query);
        const parts = query.toLowerCase().split('/').map(part => part.trim());
        
        const filteredData = originalData.filter(token => {
          return parts.every(part =>
            token.name.toLowerCase().includes(part) ||
            token.address.toLowerCase().includes(part) ||
            token.tokenA.toLowerCase().includes(part) ||
            token.tokenAName.toLowerCase().includes(part) ||
            token.tokenAAddress.toLowerCase().includes(part)
          );
        });
    
        const sortedData = filteredData.sort((a, b) => {
          const scoreA = calculateMatchScore(a, parts);
          const scoreB = calculateMatchScore(b, parts);
          return scoreB - scoreA; 
        });
        setSearchData(sortedData);
      }, 300), 
      [ originalData ]
  );

  useEffect(() => {
    if (searchQuery === '') {
      setSearchData(originalData.slice(0, 10));
    }
  }, [searchQuery]);


        useEffect(() => {
          const fetchTrendingData = async () => {
              try {
                  const response = await axios.get(`https://${apiUrl}/api/trending`);
                  setTrendingData(response.data);
              } catch (error) {
                  console.error('There was a problem with the fetch operation:', error);
              }
          };
          fetchTrendingData();
          const interval = setInterval(() => {
              fetchTrendingData();
          }, 60000);
          return () => clearInterval(interval);
      }, []);

      const observerCallback = useCallback((entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          console.log('Load more');
          setPageLength((prev) => prev + 10);
        }
      }, [recentTransactions]);

      useEffect(() => {
        const currentObserver = observer.current;
        if (currentObserver) currentObserver.disconnect();
        const newObserver = new IntersectionObserver(observerCallback);
        observer.current = newObserver;
        const target = document.querySelector('#sentinel');
        if (target) newObserver.observe(target);
        return () => newObserver.disconnect();
      }, [observerCallback]);
  
    return (
      <div className='page'>
        {showSearchModal && <SearchModal setShowSearchModal={setShowSearchModal} onSearch={handleSearch} showSearchResults={showSearchResults} searchData={searchData}/>}
        <ToastContainer style={{fontSize:"12px", maxWidth: "180px", left: "5px"}} autoClose={2000} position="bottom-left" hideProgressBar={false} pauseOnHover theme="dark" />
        <Header search={true} onSearch={handleSearch} tokens={trendingData.slice(0, 10)} setShowSearchModal={() => setShowSearchModal(prev => !prev)} />
        <div className="content2">
            <div className="main-section">
                <Trending tokens={trendingTokens} />

                  <>
                    <div className="chart-container">
                    <div id="tradingview-chart-container" className='chart-container'/>
                    </div>
                    <div className="recent-transactions">
                      <div className="table-container">
                        {recentTransactions.length !== 0 ? (
                          <table>
                              <thead>
                                  <tr>
                                      <th>Age</th>
                                      <th>Type</th>
                                      <th>USD</th>
                                      <th>Token</th>
                                      <th>Native</th>
                                      <th>Price</th>
                                      <th>Maker</th>
                                      <th>TXN</th>
                                  </tr>
                              </thead>
                              <tbody>
                              {getSlicedData(recentTransactions).map((transaction) => {
                                const { date, type, usd, tokenAmount, nativeAmount, price, maker } = transaction.trade.ui;
                                const flashClass = shouldFlash(date) ? 'flash' : '';
                                const rowClassName = `${flashClass} ${type === 'Buy' ? 'buy' : 'sell'}`.trim();
                                const uniqueKey = transaction.trade.Transaction.Signature;
      
                                return (
                                    <tr key={uniqueKey}>
                                        <td className={flashClass} style={{ width: "5%", fontWeight: "200", opacity: "0.5", fontSize: "12px" }}>{formatTimeDifference(date)}</td>
                                        <td className={rowClassName} style={{ width: "10%" }}>{type}</td>
                                        <td className={rowClassName} style={{ width: "10%" }}>${Number(usd).toFixed(2)}</td>
                                        <td className={rowClassName} style={{ width: "15%" }}>{Number(tokenAmount).toLocaleString('en-US')}</td>
                                        <td className={rowClassName} dangerouslySetInnerHTML={{ __html: formatNumber(Number(nativeAmount)) + " SOL" }} />
                                        <td className={rowClassName} dangerouslySetInnerHTML={{ __html: "$" + formatNumber(Number(price)) }} />
                                        <td className={rowClassName} style={{ width: "10%" }}>{maker}..</td>
                                        <td className={rowClassName} style={{ width: "5%" }}>
                                            <a href={`https://solscan.io/tx/${uniqueKey}`} target="_blank" rel="noopener noreferrer">
                                                🔗
                                            </a>
                                        </td>
                                    </tr>
                                );
                              })}
                              </tbody>
                          </table>
                        ) : (
                          <div className="loading">
                            <p>Loading recent transactions...</p>
                          </div>
                        )}
                      </div>
                        <div id="sentinel"></div>
                     
                    </div>
                  </>
                        
            </div>
            <Details className="deets" data={tokenData} poolData={poolData} />
        </div>
      </div>
    );
  };

export default TokenPage
