import React, { useState } from 'react'
import RaydiumLogo from '../assets/images/raydium.png'
import './Tabs.css'

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('All DEXes');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="tabs-container">
        <div className={`tab ${activeTab === 'All DEXes' ? 'active' : ''}`} onClick={() => handleTabClick('All DEXes')}>
          <p>All</p>
        </div>
        <div className={`tab ${activeTab === 'Raydium' ? 'active' : ''}`} onClick={() => handleTabClick('Raydium')}>
          <img src={RaydiumLogo} alt="" />
          <p>Raydium</p>
        </div>
      </div>
    </>
  )
}

export default Tabs
