// Trending.js
import React from 'react';
import './Trending.css';
import * as FaIcons from 'react-icons/fa';
import createDisplay from 'number-display';

const Trending = ({tokens}) => {

    const display = createDisplay({
        length: 5,
        decimal: 0,
        decimalPoint: '.',
        separator: ',',
        units : ['k', 'M', 'B', 'T', 'Q'],
      });

    const PercentageChange = ({ value }) => {
        const color = value >= 0 ? '#81c784' : '#e57373';
        return (
          <p className="percentage" style={{ textAlign: "right", color }}>
            {display(value)}%
          </p>
        );
    };

    const timeSincePairCreated = (dateTime) => {
        const date = new Date(dateTime);
        const now = new Date();
        const diff = now - date;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4);
        const years = Math.floor(months / 12);
        if (years > 0) {
          return `${years}y`;
        }
        if (months > 0) {
          return `${months}mo`;
        }
        if (weeks > 0) {
          return `${weeks}w`;
        }
        if (days > 0) {
          return `${days}d`;
        }
        if (hours > 0) {
          return `${hours}h`;
        }
        if (minutes > 0) {
          return `${minutes}m`;
        }   
        return `${seconds}s`;
    };

    const changeURLPath = (id) => {
        window.location.href = `/solana/?market=${id}`;
      }

    const paid = {
        address: "2c9F3UnMBxftqeSmsSCBw5yXjwD9hXFsMLXti9mqTwpr",
        tokenA: "Example",
        price_change_percentage: {
            h1: 69
        },
        pool_created_at: "2021-09-01T00:00:00Z",
        marketAddress: "2c9F3UnMBxftqeSmsSCBw5yXjwD9hXFsMLXti9mqTwpr"
    }

  return (
    <div className="banner">
        <div className="paid-container">
            <div className="token noborder" style={{paddingRight:"20px"}} key={paid.address} onClick={() => changeURLPath(paid.marketAddress)}>
                <p className='token-name faded'>Ad</p>
                <FaIcons.FaQuestionCircle className='image' size={20} />
                <div className="token-name">{paid.tokenA}</div>
            </div>
        </div>
        <div className="trending-container">
        <div className="trending-content">
            {tokens.concat(tokens).map((token, index) => 
            {
                const correctedIndex = index % tokens.length + 1;
                if(token.img === "missing.png")
                {
                    return (
                        <div className="token" key={correctedIndex} onClick={() => changeURLPath(token.address)}>
                            <p className='token-name faded'>#{correctedIndex}</p>
                            <FaIcons.FaQuestionCircle size={20} />
                            <div className="token-name">{token.tokenA}</div>
                            <PercentageChange value={token.price_change_percentage.h1} />
                            <div className="pair-created" style={{display: "flex", alignItems: "center", justifyContent: "center", color: "#68D391", gap: "2px"}}>
                                <FaIcons.FaSeedling size={12} />
                                <div className="" style={{fontSize: "12px"}}>{timeSincePairCreated(token.pool_created_at)}</div>
                            </div>
                        </div>
                    )
            }
            else
            {
                return (
                    <div className="token" key={correctedIndex} onClick={() => changeURLPath(token.address)}>
                        <p className='token-name faded'>#{correctedIndex}</p>
                        <img src={token.img} alt={token.name} />
                        <div className="token-name">{token.tokenA}</div>
                        <PercentageChange value={token.price_change_percentage.h1} />
                        <div className="pair-created" style={{display: "flex", alignItems: "center", justifyContent: "center", color: "#68D391", gap: "2px"}}>
                            <FaIcons.FaSeedling size={12} />
                            <div className="" style={{fontSize: "12px"}}>{timeSincePairCreated(token.pool_created_at)}</div>
                        </div>
                    </div>
                )
            }
            })}
        </div>
        </div>
    </div>
  );
};

export default Trending;