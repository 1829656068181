import React, { useState, useEffect, useRef } from 'react';
import './TokenTable.css'
import * as FaIcon from "react-icons/fa";
import DOMPurify from 'dompurify';
import  createDisplay  from 'number-display';

const TokenTable = ({ data, timeframe }) => {

  const display = createDisplay({
    length: 5,
    decimal: 0,
    decimalPoint: '.',
    separator: ',',
    units : ['k', 'M', 'B', 'T', 'Q'],
  });

  let currentPeriod = timeframe;
  if (!data) {
    data = [];
  }
  const tableRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: 'volume_usd', direction: 'desc' });
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data);
    if (sortConfig.key) {
      sortData(sortConfig.key);
      setTableData(data);
    }
  }, [data]);

  function numberWithCommas(x) {
    if (isNaN(x)) return x;
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const [priceType, setPriceType] = useState('native')
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  const handleMouseEnter = () => {
    setShowPopup(true);
  };
  const handleMouseLeave = () => {
    setShowPopup(false);
  };
  const handleMouseEnter2 = () => {
    setShowPopup2(true);
  };
  const handleMouseLeave2 = () => {
    setShowPopup2(false);
  };
  const handlePriceTypeClick = () => {
    setPriceType(prev => prev === 'USD' ? 'NATIVE' : 'USD');
  }

  function formatNumber(number) {
    let numStr = number.toFixed(20); 
    numStr = numStr.replace(/0+$/, ''); 

    if (Number.isInteger(number)) {
      return number.toLocaleString();
    }

    const [integerPart, decimalPart] = numStr.split('.');
    const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString();

    if (integerPart === '0') {
      const leadingZeros = decimalPart.match(/^0+/);
      const zeroCount = leadingZeros ? leadingZeros[0].length : 0;
      const significantDigits = decimalPart.slice(zeroCount, zeroCount + 4);

      if (zeroCount > 1) {
        return `0.0<sub>${zeroCount}</sub>${significantDigits}`;
      }

      if (significantDigits === '') {
        return '0';
      }

      return `0.${significantDigits}`;
    }

    const trimmedDecimalPart = decimalPart.slice(0, 4);

    const match = trimmedDecimalPart.match(/0+$/);
    if (match) {
      const subIndex = match.index + match[0].length;
      const beforeSub = trimmedDecimalPart.slice(0, subIndex);
      const afterSub = trimmedDecimalPart.slice(subIndex);
      return `${formattedIntegerPart}.${beforeSub}<sub>${afterSub}</sub>`;
    }

    return `${formattedIntegerPart}.${trimmedDecimalPart}`;
  }
  const NumberDisplay = ({ number }) => {
    const formattedNumber = formatNumber(number);
    const sanitizedNumber = DOMPurify.sanitize(formattedNumber); 
    return (
      <p dangerouslySetInnerHTML={{ __html: sanitizedNumber }} />
    );
  };

  const PercentageChange = ({ value }) => {
    const color = value >= 0 ? '#81c784' : '#e57373';
    return (
      <td style={{ textAlign: "right", color }}>
        {numberWithCommas(value)}%
      </td>
    );
  };

  const changeURLPath = (id) => {
    window.location.href = `/solana/?market=${id}`;
  }

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sorted = [...data].sort((a, b) => {
      if (direction === 'asc') {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setTableData(sorted);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLength, setPageLength] = useState(50);
  const getSlicedData = () => {
    const startIndex = (pageNumber - 1) * pageLength;
    const endIndex = startIndex + pageLength;
    return tableData.slice(startIndex, endIndex);
  };

  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? <FaIcon.FaArrowUp style={{ color: "#81c784", marginLeft: "4px", marginRight: "4px"}} /> : <FaIcon.FaArrowDown style={{ color: "#e57373", marginLeft: "4px", marginRight: "4px"}}/>;
    }
    return null;
  };

  return (
    <>
      <div className="table-container">
        <table className="token-table" ref={tableRef}>
          <thead>
            <tr>
              <th>Pair</th>
              <th >{priceType === 'USD' ? 'Token' : 'Native'} Price {priceType === 'USD' ? <FaIcon.FaMoneyBill onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} size={18} className='reacticon pointer opaque' onClick={() => handlePriceTypeClick()} /> : <FaIcon.FaCoins onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} size={18} className='reacticon pointer opaque' onClick={() => handlePriceTypeClick()}/>} 
              {showPopup && (
                <div className="info-popup">
                  {priceType === 'USD' ? <p className='popup'>USD price</p> : <p className='popup'>Native token price</p>}
                </div>
              )}</th>
              <th >TXNs</th>
              <th >Volume</th>
              <th onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>Makers
                {showPopup2 && (
                  <div className="info-popup">
                    <p className='popup2'>Unique wallets that bought/sold this pair</p>
                  </div>
                )}
              </th> 
              <th>5m</th>
              <th>1h</th>
              <th>6h</th>
              <th>24h</th>
              <th>Liquidity</th>
              <th>MCap</th>
            </tr>
          </thead>
          <tbody>
            {getSlicedData().map((item, index) => (
              <tr key={item.address} onClick={() => changeURLPath(item.address)}>
                <td style={{ verticalAlign: 'middle', lineHeight: '1', height: '100%' }}>
                  <span style={{ opacity: '0.3', fontSize: '12px', marginRight: '10px', display: 'inline-block', verticalAlign: 'middle', fontWeight: "200" }}># {index + 1}</span>
                  <span style={{ verticalAlign: 'middle' }}><strong>{item.name.split('/')[0]}</strong><span style={{opacity: "0.3"}}>/{item.name.split('/')[1]}</span></span>
                  {item.img !== "missing.png" &&
                  <img src={item.img} className='rowimg' alt='' style={{ height: '20px', marginLeft: '8px', verticalAlign: 'middle' }} />}
                  <span style={{ opacity: '1', fontSize: '12px', marginLeft: '8px', display: 'inline-block', verticalAlign: 'middle', textTransform: "capitalize", fontWeight: "200"}}>{item.tokenAName}</span>

                </td>                
                <td style={{ textAlign: "right" }}>
                  {priceType === 'USD' ? '$' : ''}
                  <NumberDisplay number={
                    priceType === 'USD' ? Number(item.base_token_price_usd) :
                    Number(item.base_token_price_native_currency)
                  } />
                  {priceType === 'USD' ? "" : ""}
                </td>
                <td style={{textAlign: "right"}}>{display(item.totalTxs)}</td>
                <td style={{textAlign: "right"}}>${
                  currentPeriod === '24h' ? display(item.volume_usd.h24) :
                  currentPeriod === '6h' ? display(item.volume_usd.h6) :
                  currentPeriod === '1h' ? display(item.volume_usd.h1) :
                  currentPeriod === '5m' ? display(item.volume_usd.m5) : ''
                }</td>
                <td style={{textAlign: "right"}}>{
                  currentPeriod === '24h' ? display(item.transactions.h24.buyers + item.transactions.h24.sellers) :
                  currentPeriod === '6h' ? display(Math.floor((item.transactions.h1.buyers * 6) + (item.transactions.h1.sellers * 6))) :
                  currentPeriod === '1h' ? display(Math.floor(item.transactions.h1.buyers + item.transactions.h1.sellers)) :
                  currentPeriod === '5m' ? display(Math.floor((item.transactions.h1.buyers / 12) + (item.transactions.h1.sellers / 12))) : ''
                }</td>
                <PercentageChange value={item.price_change_percentage.m5} />
                <PercentageChange value={item.price_change_percentage.h1} />
                <PercentageChange value={item.price_change_percentage.h6} />
                <PercentageChange value={item.price_change_percentage.h24} />
                <td style={{textAlign: "right"}}>${display(item.reserve_in_usd)}</td>
                <td style={{textAlign: "right"}}>${item.market_cap_usd > 0 ? display(Number(item.market_cap_usd)) : display(Number(item.fdv_usd))}</td>
              </tr>
            ))
            }
          </tbody>
        </table>
      </div>
      <div className="page-controls">
        <button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}><FaIcon.FaArrowLeft size={10} /></button>
        <p>Page {pageNumber}</p>
        <button onClick={() => setPageNumber(pageNumber + 1)}><FaIcon.FaArrowRight size={10} /></button>
        <button onClick={() => setPageLength(pageLength - 50)} disabled={pageLength <= 50}>-</button>
        <p>Limit<span>({pageLength})</span></p>
        <button onClick={() => setPageLength(pageLength + 50)}>+</button>
      </div>
    </>
  );
}

export default TokenTable;
