import React, { useState, useContext } from 'react';
import logo from '../assets/images/logo.png'
import SolanaLogo from '../assets/images/sol.png'
import * as FaIcon from "react-icons/fa";
import './Header.css'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css'; // Ensure this import is here
import { WalletModalContext } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import createDisplay from 'number-display';

const Header = ({ tokens, search, setShowSearchModal }) => {

    const { publicKey, connected, disconnect } = useWallet();
    let walletAddtext = publicKey?.toBase58();
    const { setVisible } = useContext(WalletModalContext);
    const [activeLogo, setActiveLogo] = useState(SolanaLogo);

    const homePage = () => {
        window.location.href = '/';
    }

    const openmultiwalletconnect = () => {
        if (!connected) {
        setVisible(true); 
        }
    }

    const handleDisconnect = () => {
        disconnect();
    }

    const changeURLPath = (id) => {
        window.location.href = `/solana/?market=${id}`;
      }

      const display = createDisplay({
        length: 5,
        decimal: 0,
        decimalPoint: '.',
        separator: ',',
        units : ['k', 'M', 'B', 'T', 'Q'],
      });

      const PercentageChange = ({ value }) => {
        const color = value >= 0 ? '#81c784' : '#e57373';
        return (
          <p className="percentage" style={{ textAlign: "right", color, fontSize: "10px" }}>
            {display(value)}%
          </p>
        );
    };

    const timeSincePairCreated = (dateTime) => {
        const date = new Date(dateTime);
        const now = new Date();
        const diff = now - date;
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(weeks / 4);
        const years = Math.floor(months / 12);
        if (years > 0) {
          return `${years}y`;
        }
        if (months > 0) {
          return `${months}mo`;
        }
        if (weeks > 0) {
          return `${weeks}w`;
        }
        if (days > 0) {
          return `${days}d`;
        }
        if (hours > 0) {
          return `${hours}h`;
        }
        if (minutes > 0) {
          return `${minutes}m`;
        }   
        return `${seconds}s`;
    };

  return (
    <>
        <div className='header'>
        <div className="logo">
            <img src={logo} alt="logo" onClick={() => homePage()}/>
        </div>
        {search &&
        <>
        <div className="search-bar">
            <button onClick={() => setShowSearchModal(prev => !prev)} className='searchbtn'><div className='flex'><FaIcons.FaSearch size={14} color='white' style={{marginRight: "10px"}} />Search</div><div><div className="borderbox">/</div></div></button>
        </div>
        <div className="linebreak"/>
        <p style={{marginLeft: "10px", marginTop: "10px"}}>Trending</p>
        <div className="nav-bar">
            <ul className='nav-list'>
                {tokens.map((token, index) => (
                    token.img === "missing.png" ?
                    <li key={index} className='list-item' onClick={() => changeURLPath(token.address)}>
                        <p>{index + 1}</p>
                        <FaIcons.FaQuestionCircle size={20}/>
                        <p>{token.tokenA}</p>
                    </li> :
                    <li key={index} className='list-item' onClick={() => changeURLPath(token.address)}>
                        <p className='faded'>#{index+1}</p>
                        <img className='reacticon' src={token.img} alt="" />
                        <p style={{marginLeft: "8px", fontSize: "12px", opacity: "0.8", marginRight: "8px"}}>{token.tokenA}</p>
                        <PercentageChange value={token.price_change_percentage.h1} />
                            <div className="pair-created" style={{display: "flex", alignItems: "center", justifyContent: "center", color: "#68D391", gap: "2px"}}>
                                <FaIcons.FaSeedling size={10} />
                                <div className="" style={{fontSize: "10px"}}>{timeSincePairCreated(token.pool_created_at)}</div>
                            </div>
                    </li>
                ))}
            </ul>
        </div>
        </>
        }
        <div className="bottom-box">
        <div className="login">
                <div className="signin">
                    <WalletMultiButton/>
                </div>
            </div>
            <div className="sidebar-links">
                <FaIcon.FaTwitter className='' size={18} onClick={ () => window.open("https://x.com/DexFriendscom")} />
                <FaIcon.FaTelegramPlane className='' size={18} onClick={ () => window.open("https://t.me/dexfriendscom")}/>
                <AiIcons.AiFillMail className='' size={18} onClick={ () => window.open("mailto:info@dexfriends.com")}/>
            </div>
        </div>
        </div>
        <div className='header-top'>
            <div className="logo-top" onClick={() => homePage()}>
                <img src={logo} alt="logo"/>
            </div>
            
            <div className="search-button" onClick={() => {
                if (connected) {handleDisconnect()}
                openmultiwalletconnect()
                }}>
                <FaIcon.FaUserAlt className='searchbutton' size={14}/>{connected ? walletAddtext.slice(0,4) + ".." + walletAddtext.slice(-4) : 'Connect'}
            </div>
            <div className="rightt">
                <div className="explore-menu">
                    <img className='menuimg' src={activeLogo} alt="" />
                    <p>EXPLORE</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Header