import React, { useEffect, useState } from 'react'
import './Details.css'
import Chat from './Chat'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import DOMPurify from 'dompurify';
import { useWallet } from '@solana/wallet-adapter-react';
import createDisplay from 'number-display';
import solanaLogo from '../assets/images/sol.png';
import raydiumLogo from '../assets/images/raydium.png';

const Details = ({ data }) => {
    const [showChat, setShowChat] = useState(false);
    const [currentAdvert, setCurrentAdvert] = useState({
        image: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.stagemagazine.org%2Fwp-content%2Fuploads%2F2012%2F06%2Fad_placeholder1.jpg&f=1&nofb=1&ipt=c2734006088090e6db27b76cb37de99d736ebdbbcb690eb4f71e8f74ca4414f0&ipo=images',
        title: 'Advertise Here 🗣',
        description: 'Your ad could be here! Click to learn more.',
        links: {
            chart: 'https://dexfriends.com/advertise',
            website: 'https://dexfriends.com/advertise',
            twitter: 'https://dexfriends.com/advertise',
            telegram: 'https://dexfriends.com/advertise'
        },
    });
    const display = createDisplay({
        length: 5,
        decimal: 0,
        decimalPoint: '.',
        separator: ',',
        units : ['k', 'M', 'B', 'T', 'Q'],
      });
    const { connected } = useWallet();
    const defaultData = {
        base_token_price_usd: 0,
        base_token_price_quote_token: 0,
        reserve_in_usd: 0,
        fdv_usd: 0,
        market_cap_usd: 0,
        price_change_percentage: {
            m5: 0,
            h1: 0,
            h6: 0,
            h24: 0
        },
        totalTransactions: 0,
        transactions: {
            h24: {
                buys: 0,
                sells: 0
            }
        },
        volume_usd: {
            h24: 0
        }
    };
    const resolvedData = data.base_token_price_usd ? data : defaultData;
    const buys = Number(resolvedData.transactions?.h24.buys);
    const sells = Number(resolvedData.transactions?.h24.sells);
    const total = buys + sells;

    const redWidth = (sells / total) * 100;
    const greenWidth = (buys / total) * 100;

    const buyers = Number(resolvedData.transactions?.h24.buyers);
    const sellers = Number(resolvedData.transactions?.h24.sellers);
    const total2 = buyers + sellers;

    const redWidth2 = (sellers / total2) * 100;
    const greenWidth2 = (buyers / total2) * 100;
    const userImage = resolvedData.userData?.image;
    const tokenImg = userImage || resolvedData.img;
    
    const bannerImage = resolvedData.userData?.banner

    const formatPercentage = (value) => {
        if (value == null) return 'N/A';
        const numberValue = Number(value);
        const colorClass = numberValue < 0 ? 'red-tone' : 'green-tone';
        return (
            <span className={colorClass}>
                {numberValue.toLocaleString('en-US')}%
            </span>
        );
    };

    const formatNumber = (num) => {
        num = Number(num);
        if (num === null || num === undefined) return 'N/A';
        if (num < 1000) return num.toFixed(2);
        const units = ['k', 'm', 'b', 't'];
        let unitIndex = -1;
        let scaledNum = num;
        while (scaledNum >= 1000 && unitIndex < units.length - 1) {
            scaledNum /= 1000;
            unitIndex++;
        }
        return scaledNum.toFixed(2) + units[unitIndex];
    };

    const handleTradeOnRaydium = (token) => {
        window.open(`https://raydium.io/swap/?outputMint=${token}&inputMint=sol`, '_blank');
    };

    function formatNumber2(number, bool) {
       
      if (number >= 1) {
        return number.toLocaleString();
      }

      let symbol = '';
      if (bool === true) {
        symbol = '$';
      }

        let numStr = number.toString();

        const [integerPart, decimalPart] = numStr.split('.');
        const formattedIntegerPart = parseInt(integerPart).toLocaleString();

        if (integerPart === '0') {
            const leadingZeros = decimalPart?.match(/^0+/);
            const zeroCount = leadingZeros ? leadingZeros[0].length : 0;
            const significantDigits = decimalPart?.slice(zeroCount, zeroCount + 4);

            if (zeroCount > 1) {
                return `${symbol}0.0<sub>${zeroCount}</sub>${significantDigits}`;
            } else if (zeroCount === 1) {
                return `${symbol}0.0${significantDigits}`;
            } else {
                return `${symbol}0.${significantDigits}`;
            }
        }

        return `${formattedIntegerPart}.${decimalPart}`;
    }
      
    const NumberDisplay = ({ number, symbol, prefix }) => {
        const formattedNumber = formatNumber2(number, symbol);
        const sanitizedNumber = DOMPurify.sanitize(formattedNumber); 
        return (
            <p dangerouslySetInnerHTML={{ __html: prefix + sanitizedNumber + " " + symbol }} />
        );
      };

      useEffect(() => {
        const elements = document.querySelectorAll('.data-box');
        elements.forEach(element => {
            element.classList.add('flash');
            setTimeout(() => {
                element.classList.remove('flash');
            }, 500); 
        });
      }, [data]);

      function timeAgo(timestamp) {
        if (!timestamp) return 'N/A';
        const dt = new Date(timestamp);
      
        const now = new Date();
      
        const diff = now - dt;
      
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      
        const result = `${days}d ${hours}h ${minutes}m ago`;
        return result;
      }

        function copyContents(contents) {
            if (!contents) {
            const el = document.createElement('textarea');
            el.value = resolvedData.address;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            } else {
            const el = document.createElement('textarea');
            el.value = contents;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            }
        }

        function trimAddress(address) {
            if (!address) return '';
            return `${address.slice(0, 4)}...${address.slice(-4)}`;
        }

        function goToSolScan(address) {
            window.open(`https://solscan.io/address/${address}`, '_blank');
        }

        function searchTwitterWith$Prefix(token) {
            window.open(`https://twitter.com/search?q=%24${token}`, '_blank');
        }

        console.log(resolvedData.base_token_price_usd);
    
  return (
    <div className="details-container">
        <div className="token-details">
            <div className="wide">
                <div className="name">
                    {tokenImg?.toString() === 'missing.png' ? <FaIcons.FaQuestionCircle size={24} style={{marginRight: "8px"}} /> : <img src={tokenImg} alt="" />}
                    <h1>{resolvedData.tokenA} - {resolvedData.tokenB}</h1>
                </div>
                <div className="name">
                    <FaIcons.FaGlobe style={{marginLeft: "0px",  marginRight: "8px"}}/>
                    <FaIcons.FaTwitter style={{marginLeft: "4px", marginRight: "4px"}}/>
                    <FaIcons.FaTelegram style={{marginLeft: "8px"}}/>
                </div>
            </div>
            <div className="line">
                <img src={solanaLogo} style={{width: "20px", height: "auto"}} alt="" />
                <p className='faded'>Solana</p> 
                <p className='faded'>{">"}</p>
                <img src={raydiumLogo} style={{width: "20px", height: "auto"}} alt="" />
                <p className='faded'>Raydium</p>
            </div>
            <div className="line">
            <div className="banner">
                <img style={{width: "320px", maxHeight: "120px", marginLeft: "auto", marginRight: "auto", borderRadius: "10px"}} src={bannerImage} alt="" />
            </div>
            </div>
            <div className="line">
                <div className="data-box">
                    <h2>PRICE USD</h2>
                    <NumberDisplay number={Number(resolvedData.base_token_price_usd)} symbol="" prefix="$"></NumberDisplay>
                </div>
                <div className="data-box">
                    <h2>PRICE</h2>
                    <NumberDisplay number={Number(resolvedData.base_token_price_quote_token)} symbol='SOL' prefix=""></NumberDisplay>
                </div>
            </div>
            <div className="line">
            <div className="data-box">
                <h2>LIQUIDITY</h2>
                <p>${formatNumber(Number(resolvedData.reserve_in_usd))}</p>
            </div>
            <div className="data-box">
                <h2>FDV</h2>
                <p>${formatNumber(Number(resolvedData.fdv_usd))}</p>
            </div>
            <div className="data-box">
                <h2>MCAP</h2>
                <p>${Number(resolvedData.market_cap_usd) > 0 
                    ? formatNumber(Number(resolvedData.market_cap_usd)) 
                    : formatNumber(Number(resolvedData.fdv_usd))}</p>
            </div>
        </div>
            <div className="line2">
                <div className="data-box2">
                    <h2>5M</h2>
                    <p>{formatPercentage(resolvedData.price_change_percentage?.m5)}</p>
                </div>
                <div className="data-box2">
                    <h2>1H</h2>
                    <p>{formatPercentage(resolvedData.price_change_percentage?.h1)}</p>
                </div>
                <div className="data-box2">
                    <h2>6H</h2>
                    <p>{formatPercentage(resolvedData.price_change_percentage?.h6)}</p>
                </div>
                <div className="data-box2">
                    <h2>24H</h2>
                    <p>{formatPercentage(resolvedData.price_change_percentage?.h24)}</p>
                </div>
            </div>
                <div className="" style={{marginLeft: "auto", marginRight: "20px"}}>
                <div className="data-box">
                    <div className="inline">
                    <div className="stack" style={{ margin: "10px"}}>
                        <h2>TXNS</h2>
                        <p>{resolvedData.totalTransactions ? resolvedData.totalTransactions.toLocaleString('en-US') : Number(resolvedData.transactions?.h24.buys + resolvedData.transactions?.h24.sells).toLocaleString('en-US')}</p>
                    </div>
                    <div className="stack" style={{width: "100%"}}>
                    <div className="bar-tags" style={{ display: "flex", justifyContent: "space-between", width: "90%", marginLeft: "auto", marginRight: "auto"}}>
                        <p className="green" style={{fontSize: "10px", textAlign: "left"}}>Buys <br/>{formatNumber(buys)}</p>
                        <p className="red" style={{fontSize: "10px", textAlign: "right"}}>Sells <br/>{formatNumber(sells)}</p>
                    </div>
                    <div className="bar-container" style={{width: "90%", marginLeft: "auto", marginRight: "auto"}}>
                        <div className="bar green" style={{ width: `${greenWidth}%` }}></div>
                        <div className="bar red" style={{ width: `${redWidth}%` }}></div>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="spacer"/>
                    <div className="data-box">
                    <div className="inline">
                    <div className="stack" style={{ margin: "10px"}}>
                        <h2>MAKERS</h2>
                        <p>{(Number(resolvedData.transactions?.h24.buyers) + Number(resolvedData.transactions?.h24.sellers)).toLocaleString('en-US')}</p>
                    </div>
                    <div className="stack" style={{width: "100%"}}>
                    <div className="bar-tags" style={{ display: "flex", justifyContent: "space-between", width: "90%", marginLeft: "auto", marginRight: "auto"}}>
                        <p className="green" style={{fontSize: "10px", textAlign: "left"}}>Buyers <br/>{formatNumber(buyers)}</p>
                        <p className="red" style={{fontSize: "10px", textAlign: "right"}}>Sellers <br/>{formatNumber(sellers)}</p>
                    </div>
                    <div className="bar-container" style={{width: "90%", marginLeft: "auto", marginRight: "auto"}}>
                        <div className="bar green" style={{ width: `${greenWidth2}%` }}></div>
                        <div className="bar red" style={{ width: `${redWidth2}%` }}></div>
                    </div>
                    </div>
                    </div>
                </div>
                    <div className="spacer"></div>
                    <div className="data-box">
                    <div className="inline">
                    <div className="stack" style={{ margin: "10px"}}>
                        <h2>VOLUME</h2>
                        <p>${formatNumber(Number(resolvedData.volume_usd?.h24))}</p>
                    </div>
                    </div>
                </div>
                <div className="spacer"></div>
            </div>
        </div>
        <div className="spacer"></div>
        <div className="advert">
            <div className="adtag">
                <p>Ad</p>
            </div>
            <div className="flex">
                <img src={currentAdvert.image} alt="" />
                <div className="stack">
                    <h2>{currentAdvert.title}</h2>
                    <p>{currentAdvert.description}</p>
                </div>
            </div>
            <div className="links">
                <a className='linkk wid' href={currentAdvert.links.chart} target="_blank" rel="noreferrer">Chart <FaIcons.FaChartArea /></a>
                <a className='linkk' href={currentAdvert.links.website} target="_blank" rel="noreferrer"><FaIcons.FaGlobe /></a>
                <a className='linkk' href={currentAdvert.links.twitter} target="_blank" rel="noreferrer"><FaIcons.FaTwitter /></a>
                <a className='linkk' href={currentAdvert.links.telegram} target="_blank" rel="noreferrer"><FaIcons.FaTelegramPlane /></a>
            </div>
            <p className='btmtxt'>📣 Advertise your token</p>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        {/* {connected ? <Chat /> : <Chat disabled={true} />}  */}
        <div className="spacer"></div>
        <div className="spacer"></div>
        <button className='tradebtn highlighted-border' onClick={() => handleTradeOnRaydium( resolvedData.tokenAAddress )}>
            <FaIcons.FaExchangeAlt />
            <p>Trade on Raydium</p>
        </button>
        <div className="spacer"></div>
        <div className="poolinfo">
            <div className="textline">
                <p>Pair Created</p>
                <strong>{timeAgo(resolvedData.pool_created_at)}</strong>
            </div>

            <div className="textline">
                <p>Liquidity <span style={{opacity: "0.3", fontSize: "11px"}}>(USD, Combined)</span></p>
                <strong>${formatNumber(resolvedData.reserve_in_usd)}</strong>
            </div>

            <div className="textline">
                <p>Pair <span style={{opacity: "0.3", fontSize: "11px"}}>({resolvedData.tokenA}/{resolvedData.tokenB})</span></p>
                <div className="flex gap">
                <div className='copyBox' onClick={() => copyContents(resolvedData.address)}><FaIcons.FaRegCopy style={{marginRight: "4px"}} />{trimAddress(resolvedData.address)}</div>
                <p className='link' onClick={() => goToSolScan(resolvedData.address)}>EXP <FaIcons.FaLink /></p>
                </div>
            </div>

            <div className="textline">
                <p>{resolvedData.tokenA}</p>
                <div className="flex gap">
                    <div className='copyBox' onClick={() => copyContents(resolvedData.tokenAAddress)}><FaIcons.FaRegCopy style={{marginRight: "4px"}} />{trimAddress(resolvedData.tokenAAddress)}</div>
                    <p className='link' onClick={() => goToSolScan(resolvedData.tokenAAddress)}>EXP <FaIcons.FaLink /></p>
                </div>
            </div>

            <div className="textline">
                <p>{resolvedData.tokenB}</p>
                <div className="flex gap">
                <div className='copyBox' onClick={() => copyContents(resolvedData.tokenBAddress)}><FaIcons.FaRegCopy style={{marginRight: "4px"}} />{trimAddress(resolvedData.tokenBAddress)}</div>
                <p className='link' onClick={() => goToSolScan(resolvedData.tokenBAddress)}>EXP <FaIcons.FaLink /></p>
                </div>
            </div>
        </div>
        <div className="two" style={{marginBottom: "20px", marginLeft: "20px", marginRight: "20px"}}>
            <button className='tradebtn smaller' style={{width: "150%"}} onClick={() => searchTwitterWith$Prefix(resolvedData.tokenA)}>
                {/* logo for 'X' aka twitter */}
                <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.vecteezy.com%2Fsystem%2Fresources%2Fpreviews%2F027%2F395%2F710%2Foriginal%2Ftwitter-brand-new-logo-3-d-with-new-x-shaped-graphic-of-the-world-s-most-popular-social-media-free-png.png&f=1&nofb=1&ipt=7a4cb72fbee14c6e1e7ce01bc9a0f1482cdd35221fc78489fa1eb19e2ac3453d&ipo=images" alt="" style={{maxWidth: "20px"}}/>
                <p>Search on Twitter</p>
            </button>
            <button className='tradebtn smaller'>
                <AiIcons.AiOutlineSearch size={14} />
                <p>Other Pairs</p>
            </button>
        </div>
        <div className="inline" style={{marginBottom: "20px", marginLeft: "20px", marginRight: "20px"}}>
        <p className='tradingview' style={{textWrap: "wrap", fontSize: "11px", fontWeight: "200", opacity: "0.7", textAlign: "left", maxWidth: "100%", marginLeft: "auto", marginRight: "auto"}}>
                        Dexfriends uses TradingView technology to display trading data on charts. TradingView is a charting platform for traders and investors, loved and visited by millions of users worldwide. It offers state-of-the-art charting tools and a space where people driven by markets can track important upcoming events in <a href="https://www.tradingview.com/economic-calendar/">how to read forex economic caledndar</a>, chat, chart, and prepare for trades.
        </p>
        </div>
        <div className="chat-container2"style={{height: showChat ? "50%" : "40px" }}>
        <div className="inline" style={{ gap: "8px", display: "flex", alignItems: "center", justifyContent: "center", height: "40px" }} onClick={() => setShowChat(prev => !prev)}>
                <FaIcons.FaComments />
                <p>Chat</p>
                {showChat ? <FaIcons.FaChevronDown size={12} /> : <FaIcons.FaChevronUp size={12} />}
        </div>
        {showChat ? connected ? <Chat /> : <Chat disabled={true} />  : null}
        </div>
    </div>
  )
}

export default Details