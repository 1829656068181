import './App.css';
import Main from './components/Main';
import TokenPage from './components/TokenPage';
import Update from './components/TokenUpdate';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WalletConnectionProvider from './WalletContextProvider';

function App() {
  return (
    <WalletConnectionProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main theme="dark" />} />
          <Route path="/solana" element={<TokenPage theme="dark"/>} />
          <Route path="/updateTokenInfo" element={<Update />} />
        </Routes>
      </Router>
    </WalletConnectionProvider>
  );
}

export default App;
