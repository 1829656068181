import React, { useState, useEffect, useRef, useContext } from 'react';
import './Chat.css';
import * as FaIcons from 'react-icons/fa';
import io from 'socket.io-client';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletModalContext } from '@solana/wallet-adapter-react-ui';

const apiUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_URL_DEV2
  : process.env.REACT_APP_API_URL_PROD2;

const socket = io(`https://${apiUrl}`);
let socketId;

socket.on('usernameAssigned', (data) => {
  socketId = data.socketId;
});

const marketAddress = window.location.search.split('=')[1];

const Chat = ({ disabled }) => {
  const { setVisible } = useContext(WalletModalContext);
  const { publicKey, connected, disconnect } = useWallet();
  const messagesContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!marketAddress) return;
    async function fetchMessages() {
      try {
        const serverResponse = await fetch(`https://${apiUrl}/api/messages/${marketAddress}`);
        const serverResponseJson = await serverResponse.json();
        setMessages(serverResponseJson);
      } catch (error) {
        console.error('Failed to fetch recent transactions:', error);
      }
    }
    fetchMessages();
    if (!publicKey) return;
    socket.emit('joinRoom', { marketAddress, username: publicKey.toBase58() });
    socket.on('newMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });
    return () => {
      socket.off('newMessage');
    };
  }, [marketAddress, publicKey]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    try {
      await fetch(`https://${apiUrl}/api/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ marketAddress, message, socketId, username: publicKey.toBase58()})
      });
      setMessage('')
    } catch (err) {
      console.error('Failed to send message', err);
    }
  };

  const openMultiWalletConnect = () => {
    if (!connected) {
      setVisible(true); 
    }
  }

  const handleLogOut = () => {
    disconnect();
  };

  return (
    <>
    <div className="chat-container">
      <div className="chat-header">
        <div className="username" onClick={() => {
          if (connected) {handleLogOut()}
          openMultiWalletConnect()
        }
          }>
          {connected ? `${publicKey.toBase58().slice(0, 4)}..${publicKey.toBase58().slice(-4)}` : 'Connect Wallet'}
        </div>
        <div className="voice-chat">
          <FaIcons.FaMicrophone size={12} className='mic' />
        </div>
      </div>
      <div className="chat-messages" ref={messagesContainerRef}>
        <ul>
          {messages.map((msg) => (
            <li key={msg._id}>
              <div className="wide">
                <div className="div">
                  <strong>{msg.username.length > 10 ? msg.username?.slice(0, 4) + ".." + msg.username?.slice(-4) : msg.username}:</strong> <div className="message">{msg.message}</div>
                </div>
                <div className="timestamp">
                  {new Date(msg.timestamp).toTimeString().split(' ')[0].slice(0, -3).toString()}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="chat-input">
        <input
          type="text"
          placeholder={disabled ? "Connect wallet to chat" : "Type a message"}
          value={message}
          disabled={disabled}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button className='btn' disabled={disabled} onClick={() => handleSend()}><FaIcons.FaTelegramPlane /></button>
      </div>
    </div>
  </>
  );
};

export default Chat;
